// ========================
// ORGANISATION
// ========================
// @note: Basically just a logo and a link, used in the slider
// ========================
.Organisation {
  display: block;
  user-select: none;
  opacity: 1;
  transform: scale(1);
  transition: opacity 150ms linear, transform 150ms ease(in-out-quart);
}

// ========================
// ELEMENTS
// ========================
// .Organisation-logo {}

// ========================
// MODIFIERS
// ========================
// .Organisation--modifier {}

// ========================
// UI STATES
// ========================
.Organisation:hover {
  transform: scale(0.95);
  opacity: 0.75;
}
