// ========================
// PAGE CALL TO ACTION
// ========================
// @note: Used to promote a page using a title and button
// ========================
.PageCTA {
  color: $text;
  background: $teal;
  padding: 40px 0;

  // https://www.bugherd.com/projects/259469/tasks/55
  // @media #{$tablet} {
  //   background: $green-dark center/cover url('/images/ui/background-logomark-dark-positionedleft.png') no-repeat;
  // }

  @media #{$laptop} {
    padding: 60px 0;
  }
}
