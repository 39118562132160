// ============
// Colours
// ============

// Brand ============
$red:         #B35B49;
$orange:      #DE9659;
$blue:        #353F48;
// $blue-dark:   #28343B;
$blue-light:  #335061;
$green:       #789D90;
$green-light: #82AB9C;
$green-dark:  #499276;
// $grey:        #98999B;
$grey-hover:  #f1f3f5;
$grey-active: #e2e6ea;

// Brand (2023 Refresh) ============
$teal: #00DCA7;
$teal-hover: rgba($teal, 0.1); // 10% opacity of $teal OR #E6FBF6
$teal-dark: #18bb9d;
$teal-input: #16C194;
$teal-bright: #37e1b9;
$teal-active-background: #35d8aa;
$blue-dark: #213444;
$sky-blue: #b3d4e9;
$grey: #80838e;

// Name-spacing ============
// $primary:         $blue;
$primary-light:   $blue-light;
$primary-dark:    $blue-dark;
// $secondary:       $green;
$secondary-light: $green-light;
$secondary-dark:  $green-dark;
$tertiary:        $grey;
// $tertiary-light:  #d9d9da;
$tertiary-light:  #dedede;
$tertiary-dark:   #8b8c8e;

// Name-spacing (2023 Refresh) ===========
$primary: $blue-dark;
$secondary: $teal;

// Tones ============
$black:      #000;
$off-black:  #27292f;
$grey-dark:  $tertiary-dark;
$grey-med:   $grey;
$grey-light: $tertiary-light;
$border:     $grey-med;
$off-white:  #fafafa;
$white:      #fff;

// Text ============
// $text:        $off-black;
$text-light:  $white;
// $link:        $green-dark;
// $active:      $green-light;
$highlight:   rgba($blue-light, 0.8);

// Text (2023 Refresh) ============
$link: $teal-dark;
$active: $teal-bright;


// Text (2023 Refresh) ==========
$text: #1B3546;

// Messages ============
$error:   $red;
$info:    $blue-light;
$success: $green-dark;
$warning: $orange;
