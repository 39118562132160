// ========================
// SITE SEARCH
// ========================
// @note: Search form used in the nav dropdown
// ========================
.SiteSearch {
  display: flex;

  @media #{$tablet} {
    padding-top: 2px;
  }
}

// ========================
// ELEMENTS
// ========================
.SiteSearch-label {
  @extend %font-heading !optional;
  position: absolute;
}

.SiteSearch-input {
  @include font(16px);
  @include placeholder($text);
  font-family: $area;
  font-style: normal;
  font-weight: normal;
  margin: 0;
  background: $white;
  display: block;
  appearance: none;
  padding: $h * 0.5;
  outline: none;
  width: 100%;
  border-radius: 0;
  box-shadow: none;
  border: none;

  @media #{$tablet} {
    @include font(14px);
    @include placeholder($grey);
    height: $form-element-height;
    padding: 0 $form-element-padding;
  }

  @media #{$laptop} {
    @include font(16px);
  }

  // &:focus {
  //   // box-shadow: 0 0 0 1px $form-element-focus-color;
  //   // border-color: $form-element-focus-color;
  // }
}

.SiteSearch-submit {
  appearance: none;
  border: none;
  background: none;
  margin: 0;
  padding: 0;
  width: 40px;
  text-align: center;
  flex-shrink: 0;
  color: $primary;

  @media #{$tablet} {
    width: 30px;
  }
}
