// ========================
// FULL SIZE IMAGE TEXT BLOCK
// ========================
// @note: 2-cell vertical grid with a full size image on one cell, and text on the other cell
// ========================
.FullSizeImageTextBlock {
  @extend %block-spacing !optional;

  // Applies bg color beyond the boundaries of the block
  box-shadow: 0 0 0 100vmax white;
  clip-path: inset(0 -100vmax);
}

// ========================
// ELEMENTS
// ========================
// .FullSizeImageTextBlock-element {}
.FullSizeImageTextBlock-image {
  overflow: hidden;
  margin-top: $v;
  border-radius: $image-border-radius;
}

.FullSizeImageTextBlock-content {
  padding: rem(20px) 0;

  @media #{$tablet} {
    padding: rem(30px) 0;
  }

  @media #{$laptop} {
    padding: rem(80px) 0;
  }
}

.FullSizeImageTextBlock-image-container {
  @media #{$tablet} {
    align-self: center;
  }
}

.FullSizeImageTextBlock-image {
  @media #{$tablet} {
    margin-top: 0;
  }
}

.FullSizeImageTextBlock-text-content {
  @media #{$tablet} {
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
}

// ========================
// MODIFIERS
// ========================
// .FullSizeImageTextBlock--modifier {}
.FullSizeImageTextBlock--bg-blue {
  box-shadow: 0 0 0 100vmax $sky-blue;

  .FullSizeImageTextBlock-content {
    background: $sky-blue;
  }
}

// ========================
// UI STATES
// ========================
// .FullSizeImageTextBlock.is-active {}
