// ============
// $HAMBURGER
// ============
// animates from burger to cross


// Triple bun burger ============
.Hamburger {
  position: relative;
  z-index: 2;
  display: inline-block;
  width: 20px;
  height: 14px;
  transform: rotate(0);
  transition: all 150ms ease(in-out-quart);
  cursor: pointer;
  appearance: none;
  background: none;
  border: none;
  margin: 0 0 6px;
  align-self: center;
  margin-top: 10px; // Make the hamburger align with text on logo

  @media #{$tablet} {
    display: none;
  }

  span {
    position: absolute;
    display: block;
    height: 2px;
    width: 100%;
    // background-color: $secondary-dark;
    background-color: $blue-dark;
    opacity: 1;
    left: 0;
    transform: rotate(0);
    transition: all 150ms ease(in-out-quart);
    &:nth-child(1) { top: 0; }
    &:nth-child(4) { top: 12px; }

    &:nth-child(2),
    &:nth-child(3) {
      top: 6px;
    }
  }

  // animate to closing 'X' ============
  &.is-active {
    span {
      &:nth-child(1),
      &:nth-child(4) {
        top: 6px;
        width: 0;
        left: 50%;
      }
      &:nth-child(2) { transform: rotate(45deg); }
      &:nth-child(3) { transform: rotate(-45deg); }
    }
  }
}
