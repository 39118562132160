// ========================
// BUTTON SETTINGS
// ========================


// ==========================================
// STEP 1: Set up common variables
// ==========================================
$button-height-sm:            $form-element-height;
$button-height-lg:            $form-element-height + 4;
$button-min-width:            140px;
$button-border-width:         1px;
$button-border-radius:        20px;
$button-disabled-opacity:     0.5;
$button-horizontal-padding:   $h;
$button-vertical-padding:     $v;
$button-transition-speed:     150ms;


// ==========================================
// STEP 2: Define button modifiers and states
// ==========================================
/// @type Map
/// ============
/// @prop {String} modifier - button classname modifier
/// @prop {String} state - ui state e.g focus
/// @prop {Array} colours - comma separated colour values
/// ============
/// <modifier>: (
///   <state>: (<text>, <background>, <border>)
/// )
/// ============
/// Output classes:
/// .Button--primary {...}
/// .Button--secondary {...}
/// .Button--tertiary {...}
/// ============
$button-states: (
  primary: (
    default: ($white, $primary, $white),
    focus:   ($blue-dark, $teal, null),
    hover:   ($blue-dark, $teal, $blue-dark),
    active:  ($blue-dark, $teal, null),
    loading: ($blue-dark, $teal, null)
  ),
  primary-alt: (
    default: ($white, $primary, null),
    focus:   ($blue-dark, $white, null),
    hover:   ($blue-dark, $white, null),
    active:  ($blue-dark, $white, null),
    loading: ($blue-dark, $white, null)
  ),
  secondary: (
    default: ($blue-dark, $secondary, $blue-dark),
    focus:   ($blue-dark, $white, null),
    hover:   ($blue-dark, $white, null),
    active:  ($blue-dark, $white, null),
    loading: ($blue-dark, $white, null)
  ),
  tertiary: (
    default: ($blue-dark, transparent, $blue-dark),
    focus:   ($blue-dark, $teal, null),
    hover:   ($blue-dark, $teal, transparent),
    active:  ($blue-dark, $teal, null),
    loading: ($blue-dark, $teal, null)
  ),
  secondary-outline: (
    default: ($blue-dark, transparent, $blue-dark),
    focus:   ($blue-dark, $white, $white),
    hover:   ($blue-dark, $white, $white),
    active:  ($blue-dark, $white, $white),
    loading: ($blue-dark, $white, $white)
  )
);
