@use "sass:math";

// ============
// $WIDTHS
// ============

// NOTE: Given the number of lines of CSS generated by these mixins it is
// recommended to only include those that are being used in your project.
// ALL OTHER LINES SHOULD BE COMMENTED OUT

@mixin widths($namespace: "") {
  // Whole ============
  .u-#{$namespace}width1of1 { width: 100% !important; }
  // Halves ============
  .u-#{$namespace}width1of2 { width: #{math.div(1, 2) * 100%} !important; }
  // .u-#{$namespace}width2of2 { @extend .u-#{$namespace}width1of1 !optional; }
  // Thirds ============
  .u-#{$namespace}width1of3 { width: #{math.div(1, 3) * 100%} !important; }
  .u-#{$namespace}width2of3 { width: #{math.div(2, 3) * 100%} !important; }
  // .u-#{$namespace}width3of3 { @extend .u-#{$namespace}width1of1 !optional; }
  // Quarters ============
  .u-#{$namespace}width1of4 { width: #{math.div(1, 4) * 100%} !important; }
  // .u-#{$namespace}width2of4 { @extend .u-#{$namespace}width1of2 !optional; }
  .u-#{$namespace}width3of4 { width: #{math.div(3, 4) * 100%} !important; }
  // .u-#{$namespace}width4of4 { @extend .u-#{$namespace}width1of1 !optional; }
  // Sixths ============
  .u-#{$namespace}width1of6 { width: #{math.div(1, 6) * 100%} !important; }
  // .u-#{$namespace}width2of6 { @extend .u-#{$namespace}width1of3 !optional; }
  // .u-#{$namespace}width3of6 { @extend .u-#{$namespace}width1of2 !optional; }
  // .u-#{$namespace}width4of6 { @extend .u-#{$namespace}width2of3 !optional; }
  .u-#{$namespace}width5of6 { width: #{math.div(5, 6) * 100%} !important; }
  // .u-#{$namespace}width6of6 { @extend .u-#{$namespace}width1of1 !optional; }
  // Twelfths ============
  .u-#{$namespace}width1of12 { width: #{math.div(1, 12) * 100%} !important; }
  // .u-#{$namespace}width2of12 { @extend .u-#{$namespace}width1of6 !optional; }
  // .u-#{$namespace}width3of12 { @extend .u-#{$namespace}width1of4 !optional; }
  // .u-#{$namespace}width4of12 { @extend .u-#{$namespace}width1of3 !optional; }
  // .u-#{$namespace}width5of12 { width: #{5 / 12 * 100%} !important; }
  // .u-#{$namespace}width6of12 { @extend .u-#{$namespace}width1of2 !optional; }
  // .u-#{$namespace}width7of12 { width: #{7 / 12 * 100%} !important; }
  // .u-#{$namespace}width8of12 { @extend .u-#{$namespace}width2of3 !optional; }
  // .u-#{$namespace}width9of12 { @extend .u-#{$namespace}width3of4 !optional; }
  .u-#{$namespace}width10of12 { @extend .u-#{$namespace}width5of6 !optional; }
  // .u-#{$namespace}width11of12 { width: #{11 / 12 * 100%} !important; }
  // .u-#{$namespace}width12of12 { @extend .u-#{$namespace}width1of1 !optional; }
}

@include widths();
@media #{$mobile-only} { @include widths("sm-"); }
@media #{$tablet} { @include widths("md-"); }
@media #{$laptop} { @include widths("lg-"); }
@media #{$desktop} { @include widths("xl-"); }
// @media #{$widescreen} { @include widths("xx-"); }
