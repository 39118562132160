// ========================
// VIDEO BLOCK
// ========================
// @note: Wraps the video element on content pages
// ========================
.VideoBlock {
  @extend %block-spacing !optional;
}

// ========================
// ELEMENTS
// ========================

.VideoBlock-video {
  @extend %video !optional;
  margin-top: rem(12px);
  color: $grey-dark;
  border-radius: $image-border-radius;
}

// ========================
// MODIFIERS
// ========================
// .VideoBlock-video--youtube {}
// .VideoBlock-video--vimeo {}

// ========================
// UI STATES
// ========================
// .VideoBlock.is-active {}
