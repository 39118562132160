@use "sass:math";

// ============
// $PAGINATION
// ============
$pagination-button-size: 30px;

.Pagination {
  margin-top: math.div($v, 2);
  margin-bottom: rem(40px);

  @media #{$tablet} {
    display: flex;
    align-items: center;
  }
}

// ========================
// ELEMENTS
// ========================

// <ul>
.Pagination-list {
  display: flex;
  // reset default browser list styles
  list-style: none;
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0;
}

// <li>
.Pagination-item {
  margin-right: 4px;

  a {
    @extend %font-semibold !optional;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    cursor: pointer;
    color: $grey-dark;
    height: $pagination-button-size;
    width: $pagination-button-size;
    background-color: $grey-light;
    transition: all 150ms linear;

    &:hover {
      color: $blue;
    }
  }
}

// Text, e.g. "Showing 1-5 of 20 results"
.Pagination-text {
  @extend %caption !optional;
  color: $grey-dark;
  margin-top: math.div($v, 2);

  @media #{$tablet} {
    margin-top: 0;
    margin-bottom: 0;
    margin-left: math.div($h, 2);
  }
}

// ========================
// MODIFIERS
// ========================

// << and >>
.Pagination-item--back a::before,
.Pagination-item--forward a::before {
  pointer-events: none;
  color: inherit;
}
.Pagination-item--back a::before { @include icon("arrow-left"); }
.Pagination-item--forward a::before { @include icon("arrow-right"); }

// ellipsis
// .Pagination-item--break {}

// ========================
// UI STATES
// ========================
.Pagination-item.is-active {
  a {
    background-color: $blue;
    color: $white;
  }
}

.Pagination-item.is-disabled {
  a {
    cursor: not-allowed;
    opacity: 0.2;
  }
}
