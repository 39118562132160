// ========================
// PERSON SUMMARY
// ========================
// @note: Used on people detail pages for styling profiles
// ========================
// .PersonSummary {}

// ========================
// ELEMENTS
// ========================

.PersonSummary-content {
  display: flex;
  flex-direction: column;

  @media #{$tablet} {
    flex-direction: row;
  }
}

.PersonSummary-profile {
  max-width: 400px;
  margin: 0 auto $v;

  @media #{$tablet} {
    max-width: none;
    margin: 0;
  }
}

.PersonSummary-profile-image {
  border-radius: $image-border-radius;
}

.PersonSummary-profile-details {
  margin-top: $v * 0.75;
  margin-bottom: 0;
  margin-left: -2px;
  padding: 0;
  list-style: none;

  &--img img {
    object-fit: contain;
    width: fit-content !important;
    display: block;
    height: 52px;
  }

  > li {
    line-height: 1;
    position: relative;
    padding-left: 24px;

    &:not(:last-child) {
      margin-bottom: 15px;
    }
  }

  .icon {
    position: absolute;
    top: -1px;
    left: 0;
    color: $grey-dark;
  }
}

// ========================
// MODIFIERS
// ========================
// .PersonSummary--modifier {}

// ========================
// UI STATES
// ========================
// .PersonSummary.is-active {}
