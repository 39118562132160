// ============
// $FLEX
// ============

// NOTE: Given the number of lines of CSS generated by these mixins it is
// recommended to only include those that are being used in your project.
// ALL OTHER LINES SHOULD BE COMMENTED OUT

@mixin flex($namespace: "") {

  // ============
  // Applies to flex container
  //

  // Container
  .u-#{$namespace}flex { display: flex !important; }
  // .u-#{$namespace}flexInline { display: inline-flex !important; }

  // Direction: row
  // .u-#{$namespace}flexRow { flex-direction: row !important; }
  // .u-#{$namespace}flexRowReverse { flex-direction: row-reverse !important; }

  // Direction: column
  .u-#{$namespace}flexCol { flex-direction: column !important; }
  // .u-#{$namespace}flexColReverse { flex-direction: column-reverse !important; }

  // Wrap
  .u-#{$namespace}flexWrap { flex-wrap: wrap !important; }
  // .u-#{$namespace}flexNoWrap { flex-wrap: nowrap !important; }
  // .u-#{$namespace}flexWrapReverse { flex-wrap: wrap-reverse !important; }

  // Align items along the main axis of the current line of the flex container
  // .u-#{$namespace}flexJustifyStart { justify-content: flex-start !important; }
  // .u-#{$namespace}flexJustifyEnd { justify-content: flex-end !important; }
  .u-#{$namespace}flexJustifyCenter { justify-content: center !important; }
  .u-#{$namespace}flexJustifyBetween { justify-content: space-between !important; }
  // .u-#{$namespace}flexJustifyAround { justify-content: space-around !important; }

  // Align items in the cross axis of the current line of the flex container
  // Similar to `justify-content` but in the perpendicular direction
  // .u-#{$namespace}flexAlignItemsStart { align-items: flex-start !important; }
  // .u-#{$namespace}flexAlignItemsEnd { align-items: flex-end !important; }
  .u-#{$namespace}flexAlignItemsCenter { align-items: center !important; }
  .u-#{$namespace}flexAlignItemsStretch { align-items: stretch !important; }
  // .u-#{$namespace}flexAlignItemsBaseline { align-items: baseline !important; }

  // Aligns items within the flex container when there is extra space in the cross-axis
  // Has no effect when there is only one line of flex items.
  // .u-#{$namespace}flexAlignContentStart { align-content: flex-start !important; }
  // .u-#{$namespace}flexAlignContentEnd { align-content: flex-end !important; }
  // .u-#{$namespace}flexAlignContentCenter { align-content: center !important; }
  // .u-#{$namespace}flexAlignContentStretch { align-content: stretch !important; }
  // .u-#{$namespace}flexAlignContentBetween { align-content: space-between !important; }
  // .u-#{$namespace}flexAlignContentAround { align-content: space-around !important; }

  // 1. Set the flex-shrink default explicitly to fix IE10 - http://git.io/vllC7
  // NOTE: if this one's required, whack it in an IE10 specific media query
  // .u-#{$namespace}flex > *, -#{$namespace}flexInline > * { flex-shrink: 1; } // 1


  // ============
  // Applies to flex items
  // ============

  // Override default alignment of single item when specified by `align-items`
  // .u-#{$namespace}flexAlignSelfStart { align-self: flex-start !important; }
  // .u-#{$namespace}flexAlignSelfEnd { align-self: flex-end !important; }
  // .u-#{$namespace}flexAlignSelfCenter { align-self: center !important; }
  // .u-#{$namespace}flexAlignSelfStretch { align-self: stretch !important; }
  // .u-#{$namespace}flexAlignSelfBaseline { align-self: baseline !important; }
  // .u-#{$namespace}flexAlignSelfAuto { align-self: auto !important; }

  // Change order without editing underlying HTML
  .u-#{$namespace}flexOrderFirst { order: -1 !important; }
  .u-#{$namespace}flexOrderLast { order: 1 !important; }
  // .u-#{$namespace}flexOrderNone { order: 0 !important; }

  // Specify the flex grow factor, which determines how much the flex item will
  // grow relative to the rest of the flex items in the flex container.
  // Supports 1-5 proportions
  // 1. Provide all values to avoid IE10 bug with shorthand flex - http://git.io/vllC7
  //   Use `0%` to avoid bug in IE10/11 with unitless flex basis - http://git.io/vllWx
  // .u-#{$namespace}flexGrow1 { flex: 1 1 0% !important; } // 1
  // .u-#{$namespace}flexGrow2 { flex: 2 1 0% !important; }
  // .u-#{$namespace}flexGrow3 { flex: 3 1 0% !important; }
  // .u-#{$namespace}flexGrow4 { flex: 4 1 0% !important; }
  // .u-#{$namespace}flexGrow5 { flex:   5 1 0% !important; }
  .u-#{$namespace}flexGrow1 { flex-grow: 1 !important; }

  // Aligning with `auto` margins - http://www.w3.org/TR/css-flexbox-1/#auto-margins
  .u-#{$namespace}flexExpand { margin: auto !important; }
  // .u-#{$namespace}flexExpandLeft { margin-left: auto !important; }
  // .u-#{$namespace}flexExpandRight { margin-right: auto !important; }
  // .u-#{$namespace}flexExpandTop { margin-top: auto !important; }
  // .u-#{$namespace}flexExpandBottom { margin-bottom: auto !important; }

  // Specify justify content for flexbox
  .u-#{$namespace}justifyCenter { justify-content: center; }

  // Specify align items for flexbox
  .u-#{$namespace}itemsCenter { align-items: center; }
}

@include flex();
@media #{$mobile-only} { @include flex("sm-"); }
@media #{$tablet} { @include flex("md-"); }
@media #{$laptop} { @include flex("lg-"); }
// @media #{$desktop} { @include flex("xl-"); }
// @media #{$widescreen} { @include flex("xx-"); }
