html {
  @extend %font-regular !optional;
  @include font($base-font-size);
  line-height: $base-line-height;
  color: $text;
  // Better Font Rendering
  font-feature-settings: "kern" 1;
  font-kerning: normal;
  text-rendering: optimizeLegibility;
  // -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// Text highlight color
// NOTE: must be specified individually
::selection { background: $highlight; color: $white; }
::-moz-selection { background: $highlight; color: $white; }

a {
  color: $link;
  text-decoration-line: underline;
  text-decoration-thickness: rem(1px);
  transition: color 150ms linear;

  @include hover() {
    color: $active;
  }
}

span {
  &.highlight {
    color: $teal;
  }
}

p {
  strong, b {
    @extend %font-bold !optional;
  }

  em, i {
    // Note: italics removed for 2023 brand refresh
    // @extend %font-italic !optional;
    @extend %font-regular !optional;

    strong &,
    b &,
    strong,
    b {
      // Note italics removed for 2023 brand refresh
      // @extend %font-bold-italic !optional;
      @extend %font-bold !optional;
    }
  }

  span.highlight {
    color: $teal;
  }
}

ul { list-style-type: disc; }
ol { list-style: decimal; }

ul, ol {
  margin-top: 0;
  margin-bottom: rem($base-paragraph-spacing);
  padding-left: rem(16px);
}

li {
  @extend %p !optional;
  margin-bottom: 0;

  p {
    margin-bottom: 0;
  }
}

sub,
sup {
  @extend %small !optional;
  line-height: 0;
}

hr {
  border: none;
  margin: 0;
  background-color: $border;
  height: 1px;
}

// Phone numbers ============
// Avoid wrapping phone numbers onto multiple lines
[href="tel"] { white-space: nowrap; }

