// ========================
// PAGE
// ========================
// @note: Wraps all page content and mostly used for spacing
// ========================
.Page {
  padding-top: $header--height-sm;

  @media #{$laptop} {
    padding-top: $header--height-lg;
  }
}
