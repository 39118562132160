@use "sass:math";

// ============
// $ALERT
// ============
$alert-colors: (
  error:   $error,
  warning: $warning,
  info:    $info,
  success: $success
);

.Alert {
  @extend %font-medium !optional;
  @extend %p !optional;
  border-radius: $form-element-border-radius;
  border-style: solid;
  border-width: 1px;
  padding: $v * 0.5 $h * 0.5;
  display: flex;
  align-items: flex-start;

  span {
    display: block;
  }
}

.Alert-icon {
  @include font(16px, 24px);
  margin-right: math.div($h, 2);

  @media #{$laptop} {
    @include font(18px, 27px);
  }
}

@each $type, $color in $alert-colors {
  .Alert--#{$type} {
    border-color: darken(hsl(hue($color), saturation($color), 90%), 5%);
    background-color: hsl(hue($color), saturation($color), 90%);
    color: $color;
    box-shadow: 0 1px 10px -2px transparentize($color, 0.2);
  }
}

.Alert--small {
  @extend %small !optional;
  padding: $v * 0.333 $h * 0.333;

  .Alert-icon {
    @include font(14px, 21px);
    margin-right: $h * 0.333;

    @media #{$laptop} {
      @include font(16px, 24px);
    }
  }
}
