// ========================
// SEARCH RESULTS
// ========================
// @note: Search Results List
// ========================
.SearchResults {
  list-style: none;
  padding: 0;
  margin: 0;
}

// ========================
// ELEMENTS
// ========================
.SearchResults-item {
  margin-bottom: $v;

  a {
    display: block;
    text-decoration: none;
  }
}

.SearchResults-item-title {
  @extend %h3 !optional;
  margin-top: 0;
  margin-bottom: $v * 0.25;
  display: inline-flex;

  .text {
    text-decoration: 1px underline;
  }

  .icon {
    @include font(14px, 26.4px);
    display: block;
    margin-left: rem(10px);
    color: $active;
    text-decoration: none !important;

    @media #{$laptop} {
      @include font(16px, 33px);
      margin-left: rem(15px);
      opacity: 0;
      transform: translateX(-50%);
      transition: all 150ms ease(in-out-quart);
    }
  }
}

.SearchResults-item-excerpt {
  @extend %lead !optional;
  margin-bottom: 0;
  color: $text;
}

// ========================
// MODIFIERS
// ========================
// .SearchResults--modifier {}

// ========================
// UI STATES
// ========================
.SearchResults-item a:hover {
  .icon {
    @media #{$laptop} {
      opacity: 1;
      transform: translateX(0);
    }
  }
}
