// ========================
// NAV
// ========================
// @note: Holds links in the header and contains a search toggle
// ========================
%navItem {
  @extend %font-bold !optional;
  @include font(17px);
  margin: 0;
  line-height: 1;
  display: block;
  text-decoration: none;
  color: $text;
  border: 0;
  padding: $h * 0.5;
  background: none;
  transition: all 150ms linear;
  width: 100%;
  text-align: left;
  border-top: 1px solid $blue-dark;

  @media #{$tablet} {
    font-family: $area;
    font-weight: 600;
    position: relative;
    padding: 6px;
    border: 0;

    // underline default
    &::after {
      content: '';
      display: block;
      height: 2px;
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      opacity: 0;
      transform: scaleX(0) translateY(-2px);
      background: $teal;
      transition: all 150ms ease(in-out-quart);
      will-change: opacity, transform;
    }
  }


  &:hover {
    cursor: pointer;

    @media #{$tablet} {
      background: none;
      // underline expanded
      &::after {
        opacity: 1;
        transform: scaleX(1) translateY(0);
      }
    }
  }
}

// ========================
// ELEMENTS
// ========================
.Nav {
  position: absolute;
  top: 100%;
  left: 0;
  background: $white;
  width: 100%;
  display: flex;
  flex-direction: column;
  transform: translateX(100%);
  transition: all 0.2s ease(in-out-quart);
  height: calc(100vh - #{$header--height-sm});

  @media #{$tablet} {
    flex-direction: row;
    background: none;
    width: auto;
    height: auto;
    position: static;
    transition: none;
    transform: none;
    margin-bottom: 9px; // Make nav align with logo text
  }

  @media #{$mobile-only} {
    overflow-y: scroll;
  }
}

.Nav-content {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  @media #{$tablet} {
    flex-direction: row;
    align-items: center;
  }
}

.Nav-menu {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  width: 95%;

  // Remove scrollbars imposed by width value
  overflow-x: hidden;
  overflow-y: hidden;

  // add bottom border on the last root-level link
  li.Nav-menu-link:last-of-type {
    border-bottom: 1px solid $blue-dark;
  }

  @media #{$tablet} {
    flex-direction: row;
    width: auto;
    overflow-x: unset;
    overflow-y: unset;

    li.Nav-menu-link:last-of-type {
      border-bottom: none;
    }
  }
}

.Nav-menu-link {
  > button, > a {
    @extend %navItem;
    height: 50px;

    // Vertically center within the button or a
    display: flex;
    align-items: flex-end;
  }

  @media #{$tablet} {
    > button, > a {
      display: block;
      align-items: unset;
      height: auto;
    }

    margin-right: 12px;
    position: relative;

    // Allow displaying of dropdown icons inline
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

// === DROPDOWNS
.Nav-menu-dropdown {
  transition: all 150ms ease(in-out-quart);
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  list-style: none;
  margin: 0;
  width: 100vw;
  padding: 0 0 0 2px;

  @media #{$tablet} {
    position: absolute;
    left: 50%;
    top: 100%;
    background: $white;
    transform: translateX(-50%);
    box-shadow: 0 2px 10px rgba($black, 0.2);
    border-radius: 5px;
    padding: 0;
    max-width: 240px;

    // Rounded corners on first and last links in the dropdown
    li {
      &:first-child {
        a {
          border-radius: 5px 5px 0 0;
        }
      }

      &:last-child {
        a {
          border-radius: 0 0 5px 5px;
        }
      }
    }
  }
}

.Nav-menu-dropdown-link {
  a {
    @extend %small !optional;
    display: block;
    margin: 0;
    text-decoration: none;
    color: $text;
    transition: all 150ms linear;
    padding: 10px $h * 0.5;

    &:hover, &:focus { background: $teal-hover; }

    @media #{$tablet} {
      font-family: $area;
      font-weight: 600;
      border: none;
      padding: 10px 16px;
      background: $white;

      &:hover, &:focus { background: $teal-hover; }
      &:active { background: $teal-hover; }
    }
  }
}

// === SEARCH
.Nav-search {
  width: 80%;
  margin: 20px;

  input {
    background: $grey-light;
    border-radius: 20px;

    &::placeholder {
      color: $blue-dark;
      opacity: 1;
    }
  }

  @media #{$tablet} {
    position: relative;
    align-self: center;
    margin: 0 auto;
    transform: none;
    border-radius: 0;
    width: unset;

    input {
      background: #fff;
    }
  }
}

.Nav-search-toggle {
  @extend %navItem;
  display: none;
  appearance: none;
  border: none;
  background: none;

  @media #{$tablet} {
    display: block;
    transition: all 150ms ease(in-out-quart);
    color: $blue-dark;

    &:hover {
      color: $teal;
    }

    .icon {
      width: 20px;
      height: 20px;
    }
  }
}

.Nav-search-form {
  transition: all 150ms ease(in-out-quart);
  border-radius: 20px;
  color: $blue-dark;
  background: $grey-light;

  @media #{$tablet} {
    position: absolute;
    box-shadow: 0 2px 4px rgba($black, 0.2);
    width: 300px;
    top: 100%;
    right: 0;
    opacity: 0;
    visibility: hidden;
    border-radius: 0 0 2px 2px;
    border: none;
    background: #fff;

    &::before {
      content: '';
      height: 2px;
      width: 100%;
      display: block;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}

// invisible overlay picks up outside click
.Nav-search-overlay {
  display: none;

  @media #{$tablet} {
    display: block;
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba($white, 0.4);
    backdrop-filter: blur(1px); // maybe?
    pointer-events: none;
    top: 0;
    left: 0;
    transition: all 150ms ease;
    opacity: 0;
    visibility: hidden;
  }
}


// ========================
// MODIFIERS
// ========================
.Nav-menu-link--dropdown {
  button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .icon {
    @include font(12px);
    color: $blue-dark;
    transition: all 150ms ease(in-out-quart);
    background-color: $grey-light;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5px;

    svg {
      display: block;
      margin: 0 auto;
      width: 10px;
      height: 10px;
    }
  }

  @media #{$tablet} {
    .icon {
      background-color: unset;
    }
  }
}

// ========================
// UI STATES
// ========================

// When a dropdown is open
// ========================
.Nav-menu-link--dropdown.is-active {
  .icon {
    background-color: $teal-active-background;
    transform: rotate(180deg);
  }

  // show dropdown
  .Nav-menu-dropdown {
    opacity: 1;
    visibility: visible;
    pointer-events: all;
  }

  @media #{$tablet} {
    .icon {
      transform: none;
    }

    // activate underline
    > button::after {
      opacity: 1;
      transform: scaleX(1) translateY(0);
    }

    .icon {
      background-color: unset;
      color: $teal;
    }
  }
}

.Nav-menu-link.is-current {
  .icon {
    color: $teal;
  }

  > button {
    color: $teal;
  }

  > a {
    color: $teal;
  }
}


// When the search bar is open
// ========================
.Nav.search-open {

  // only relevant above tablet
  @media #{$tablet} {

    // show the search box
    .Nav-search-form {
      opacity: 1;
      visibility: visible;
      border-radius: 5px;
    }

    // show the overlay
    .Nav-search-overlay {
      opacity: 1;
      visibility: visible;
      pointer-events: all;
    }

    // color the search icon
    .Nav-search-toggle {
      color: $teal;
    }
  }
}

.Nav.nav-open {
  @media #{$mobile-only} {
    transform: translateX(0);
  }
}

.menu-open {
  position: fixed; // 1
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  overflow-y: scroll; // fixes jumping when scrollbars are present
}
