// ========================
// IMAGE GALLERY BLOCK
// ========================
// @note: Holds an instagram style grid of images (4 column)
// ========================
.ImageGalleryBlock {
  @extend %block-spacing !optional;
}

// ========================
// ELEMENTS
// ========================
// .ImageGalleryBlock-part {}
// .ImageGalleryBlock-anotherPart {}
.ImageGalleryBlock-image > div {
  border-radius: $image-border-radius;
}

// ========================
// MODIFIERS
// ========================
// .ImageGalleryBlock--modifier {}

// ========================
// UI STATES
// ========================
// .ImageGalleryBlock.is-active {}
