@use "sass:math";

// ============
// $INPUT
// ============
// <label class="Input-label" for="demo">Input Label</label>
// <div class="Input has-error">
//   <input type="text" id="demo" placeholder="Type..." />
//   <span class="Input-note">Note: Input note...</span>
// </div>

.Input {
  position: relative;
  display: block;
  padding-top: 30px;

  input {
    @extend %font-regular !optional;
    @include font(16px, 24px);
    @extend %small !optional;
    @media #{$laptop} {
      @include font(18px, 27px);
    }

    background: $white;
    height: $form-element-height;
    display: block;
    appearance: none;
    padding-left: $h * 0.666;
    padding-right: $h * 0.666;
    outline: none;
    width: 100%;
    border-radius: $form-element-border-radius;
    box-shadow: inset 0 0 5px 0 rgba(173, 173, 173, 0.5);
    border: 1px solid $border;
  }

  // error icon
  &::before {
    @include font(16px, $form-element-height);
    @media #{$laptop} { @include font(18px, $form-element-height); }
    @include icon("notification");
    display: none;
    position: absolute;
    left: 12px;
    bottom: 0;
    color: $error;
  }
}

// ============
// Spinner
// ============
.Input-spinner {
  position: absolute;
  right: 8px;
  bottom: 8px;
  .spinner {
    animation: rotate 2s linear infinite;
    display: inline;
    vertical-align: middle;
  }
  
  .spinner .path {
    stroke: $border;
    stroke-width: 4px;
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }
}

// ============
// Label
// ============
.Input-label {
  @extend %font-semibold !optional;
  @include font(16px, 30px);
  @media #{$laptop} { @include font(18px, 30px); }

  position: absolute;
  margin: 0;
  display: flex;
  cursor: pointer;
  color: $white;
  top: 0;
  left: 0;
  width: 100%;
  align-items: center;

  &.is-required {
    &::after {
      @extend %font-semibold !optional;
      content: '\00a0\002A'; // [space][asterisk]
      display: inline-block;
    }
  }
}


// ============
// Inline note
// ============
.Input-note {
  display: none;
  @extend %caption !optional;
  line-height: 20px;
  margin: 0;
  background-color: $error;
  padding-left: $h * 0.25;
  padding-right: $h * 0.25;
  border-radius: 0 0 $form-element-border-radius $form-element-border-radius;
  box-shadow: 0 0 0 1px $error;
  border: 1px solid $error;
  color: $white;

  .has-error + & {
    display: block;
  }
}

// ============
// MODIFIERS
// ============

// Small variant
.Input--small {
  padding-top: 20px;

  input {
    @include font(14px, 21px);
    @media #{$laptop} { @include font(16px, 24px); }
    height: $form-element-height * 0.8;
    padding-left: $h * 0.333;
    padding-right: $h * 0.333;
  }

  // error icon
  &::before {
    @include font(14px, $form-element-height * 0.8);
    @media #{$laptop} { @include font(16px, $form-element-height * 0.8); }
    left: 8px;
  }

  .Input-label {
    @include font(14px, 20px);
    @media #{$laptop} { @include font(16px, 20px); }
  }
}

// Dark variant
.Input--dark {
  input {
    @include placeholder($white);
    background: $blue-light;
    box-shadow: inset 0 0 5px 0 rgba($blue-dark, 0.5);
    border: 1px solid $blue-dark;
    color: $white;
    border-radius: $form-element-border-radius;
  }
}

// No label above
.Input--noLabel {
  padding-top: 0;
  .Input-label { @extend %u-hiddenVisually; }

  input {
    margin-bottom: 0;
  }

  &.has-error {
    input {
      // Remove border radius on bottom corners for the error message to sit nicely
      border-radius: $form-element-border-radius $form-element-border-radius 0 0;
    }
  }
}

// Applies very rounded corners
.Input--rounded-heavy {
  input {
    border-radius: $form-element-heavy-border-radius;
  }
}

// Applies styling that makes the input look like a multi-select component (see multi-select.scss)
// (for the 2023 brand refresh)
.Input--grey {
  input {
    // Should be the same font declaration as on multiselect inputs (see multi-select.scss)
    @include placeholder($grey-dark);
    @include font(16px);
    @extend %font-semibold !optional;

    background: $grey-light;
    color: $grey-dark;
    border: none;
    transition: all 0.2s ease;
    box-shadow: none;
  }
}

.Input--teal {
  input {
    @include placeholder($blue-dark);
    @include font(16px, 24px);
    @media #{$laptop} { @include font(18px, 27px); }

    background: $teal-input;
    color: $blue-dark;
    border: none;
    box-shadow: none;
  }
}

.Input--large {
  padding-top: 15px;

  @media #{$laptop} { padding-top: 30px; }
}

// ============
// UI STATES
// ============

// Focus state
.Input input:focus {
  box-shadow: 0 0 0 1px $form-element-focus-color;
  border-color: $form-element-focus-color;
}

// Focus state --dark
.Input--dark input:focus {
  box-shadow: 0 0 0 1px $teal;
  border-color: $teal;
}

// Focus and hover state --grey
.Input--grey input:focus, .Input--grey input:hover {
  @include placeholder($blue-dark);
  border: none;
  box-shadow: none;
  color: $blue-dark;
  background: $teal;
}

// Error state
.Input.has-error {
  input {
    @include placeholder($error);
    box-shadow: 0 0 0 1px $error;
    border-color: $error;
    padding-left: 40px;
    background-color: hsl(hue($error), saturation($error), 95%);
    color: $error;
  }

  .Input-note {
    color: $white;
  }

  // error icon
  &::before {
    display: block;
  }
}

.Input--small.has-error {
  input {
    padding-left: 30px;
  }
}