// ========================
// PAGE BODY
// ========================
// @note: Used on most general pages and wraps all content and blocks
// ========================
.PageBody {
  padding: 30px 0;

  @media #{$laptop} {
    padding: 60px 0;
  }

  // h1, h2, h3, h4, h5, h6 {
  //   &:first-child {
  //     margin-top: 0;
  //   }
  // }
}

// ========================
// ELEMENTS
// ========================
// .PageBody-part {}
// .PageBody-anotherPart {}

// ========================
// MODIFIERS
// ========================
.PageBody--home {
  text-align: center;
}
// .PageBody--work {}
// .PageBody--news {}

// ========================
// UI STATES
// ========================
// .PageBody.is-active {}
