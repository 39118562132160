// =====================
// TYPOGRAPHY SETTINGS
// =====================
// TODO: Remove calbiri and klavika fonts?


// ==========================================
// STEP 1: Include the font using @font-face
// ==========================================
// Only woff is required when using webfonts: http://caniuse.com/#search=woff
// The following is example setup only:
// ==========================================

// @font-face {
//   font-family: "Klavika Bold";
//   font-weight: normal;
//   font-style: normal;
//   src: local("🏠"), url("/fonts/klavika/Klavika-Bold.woff") format("woff");
// }
// $klavika-bold: "Klavika Bold", system-ui, sans-serif;

$area: "area-normal", system-ui, sans-serif;

@font-face {
  font-family: "Klavika Light";
  font-weight: normal;
  font-style: normal;
  src: local("🏠"), url("/fonts/klavika/Klavika-Light-Web.woff") format("woff");
}
$klavika-light: "Klavika Light", system-ui, sans-serif;

@font-face {
  font-family: "Calibri";
  font-weight: normal;
  font-style: normal;
  src: local("🏠"), url("/fonts/calibri/Calibri-Web.woff") format("woff");
}
$calibri: "Calibri", system-ui, sans-serif;

@font-face {
  font-family: "Calibri Italic";
  font-weight: normal;
  font-style: normal;
  src: local("🏠"), url("/fonts/calibri/Calibri-Italic-Web.woff") format("woff");
}
$calibri-italic: "Calibri Italic", system-ui, sans-serif;

@font-face {
  font-family: "Calibri Bold";
  font-weight: normal;
  font-style: normal;
  src: local("🏠"), url("/fonts/calibri/Calibri-Bold-Web.woff") format("woff");
}
$calibri-bold: "Calibri Bold", system-ui, sans-serif;

@font-face {
  font-family: "Calibri Bold Italic";
  font-weight: normal;
  font-style: normal;
  src: local("🏠"), url("/fonts/calibri/Calibri-BoldItalic-Web.woff") format("woff");
}
$calibri-bold-italic: "Calibri Bold Italic", system-ui, sans-serif;


// ==========================================
// STEP 2: Setup variables and placeholders
// ==========================================
// The following is example setup only, however,
// I do not recommend changing base-font-size
// ==========================================
$base-font-size:    16px;
$base-line-height:  1.5;
$base-paragraph-spacing: 30px;
// Fluid type settings
$min-breakpoint:    320px;
$max-breakpoint:    1920px;

/// Base font styles
/// ============
/// @type Map
/// ============
/// @prop {String} font-family - font stack
/// @prop {String} font-style - string e.g. italic, bold, normal
/// @prop {String | Number} font-weight - string or number

// $base-font-styles: (
//   regular: (
//     font-family: #{$calibri},
//     font-style: normal,
//     font-weight: normal
//   ),
//   italic: (
//     font-family: #{$calibri-italic},
//     font-style: italic,
//     font-weight: normal
//   ),
//   bold: (
//     font-family: #{$calibri-bold},
//     font-style: normal,
//     font-weight: normal
//   ),
//   bold-italic: (
//     font-family: #{$calibri-bold-italic},
//     font-style: italic,
//     font-weight: normal
//   ),
//   heading: (
//     font-family: #{$klavika-light},
//     font-style: normal,
//     font-weight: normal
//   )
// );

$base-font-styles: (
  regular: (
    font-family: #{$area},
    font-style: normal,
    font-weight: normal
  ),
  // TODO: This is no longer used
  italic: (
    font-family: #{$calibri-italic},
    font-style: italic,
    font-weight: normal,
  ),
  semibold: (
    font-family: #{$area},
    font-style: normal,
    font-weight: 600,
  ),
  bold: (
    font-family: #{$area},
    font-style: normal,
    font-weight: 700
  ),
  // TODO: This is no longer used
  bold-italic: (
    font-family: #{$calibri-bold-italic},
    font-style: italic,
    font-weight: normal,
  ),
  heading: (
    font-family: #{$area},
    font-style: normal,
    font-weight: 600
  )
);


/// Base font dimensions
/// ============
/// @type Map
/// ============
/// @prop {Measurement} font-size - pixel measurement
/// @prop {Number?} line-height - optional line hight measurement (font-size * line-height)
/// @prop {Measurement?} margin-top - optional pixel measurement
/// @prop {Measurement?} margin-bottom - optional pixel measurement
/// @prop {String?} font-family - optional, name MUST match one of the names supplied in $base-font-styles, e.g. light, medium, bold.
/// @prop {Boolean?} uppercase - do you want capital letters
/// @prop {Measurement?} letter-spacing - do you want letter spacing
///
/// @NOTE: The following are required when you want multi breakpoint type:
/// @prop {Measurement?} min-size - what is the minimum font size
/// @prop {Measurement?} max-size - what is the maximum font size
///
/// @NOTE: The following are required when you want the type to be fluid:
/// @prop {Measurement?} min-breakpoint - when does the font start growing
/// @prop {Measurement?} max-breakpoint - when does the font finish growing

$base-font-dimensions: (
  h1: (
    min-size: 36px,
    max-size: 42px,
    line-height: 1.1,
    letter-spacing: 1px,
    margin-top: $base-paragraph-spacing,
    margin-bottom: $base-paragraph-spacing * 0.5,
    font-family: heading
  ),
  h2: (
    min-size: 30px,
    max-size: 36px,
    line-height: 1.1,
    margin-top: $base-paragraph-spacing,
    margin-bottom: $base-paragraph-spacing * 0.5,
    font-family: heading
  ),
  h3: (
    min-size: 17px,
    max-size: 23px,
    line-height: 1.1,
    margin-top: $base-paragraph-spacing,
    margin-bottom: $base-paragraph-spacing * 0.5,
    font-family: heading,
    color: #00DCA7
  ),
  h4: (
    min-size: 17px,
    max-size: 17px,
    line-height: 1.1,
    margin-top: $base-paragraph-spacing,
    margin-bottom: $base-paragraph-spacing * 0.5,
    font-family: heading
  ),
  h5: (
    min-size: 15px,
    max-size: 16px,
    line-height: 1.2,
    margin-top: $base-paragraph-spacing,
    margin-bottom: $base-paragraph-spacing * 0.5,
    font-family: bold
  ),
  h6: (
    min-size: 14px,
    max-size: 15px,
    line-height: 1.2,
    margin-top: $base-paragraph-spacing,
    margin-bottom: $base-paragraph-spacing * 0.5,
    font-family: bold
  ),
  p: (
    min-size: 16px,
    max-size: 18px,
    line-height: 1.4,
    margin-top: 0px,
    margin-bottom: $base-paragraph-spacing,
    font-family: regular
  ),
  lead: (
    min-size: 18px,
    max-size: 20px,
    line-height: 1.4,
    margin-top: 0px,
    margin-bottom: $base-paragraph-spacing,
    font-family: regular
  ),
  small: (
    min-size: 14px,
    max-size: 16px,
    line-height: 1.5,
    margin-top: 0px,
    margin-bottom: $base-paragraph-spacing * 0.5,
    font-family: regular
  ),
  small-semibold: (
    min-size: 14px,
    max-size: 16px,
    line-height: 1.5,
    margin-top: 0px,
    margin-bottom: $base-paragraph-spacing * 0.5,
    font-family: semibold
  ),
  caption: (
    min-size: 12px,
    max-size: 14px,
    line-height: 1,
    margin-top: 0px,
    margin-bottom: 0px,
    font-family: regular
  )
);
