// ========================
// EMBED BLOCK
// ========================
// @note: For GIS solutions iframe
// ========================
.EmbedBlock {
  @extend %block-spacing !optional;
}

// ========================
// ELEMENTS
// ========================
.EmbedBlock-iframe {
  border: none;
  overflow: hidden;
  width: 100%;
  z-index: 1;
  height: 5280px;

  @media (min-width: 510px) { // custom for this iframe
    height: 2820px;
  }
  @media (min-width: 670px) { // custom for this iframe
    height: 2330px;
  }
  @media #{$tablet} {
    height: 2280px;
  }
  @media #{$laptop} {
    height: 1860px;
  }
  @media #{$desktop} {
    height: 1720px;
  }
}
// .EmbedBlock-anotherPart {}

// ========================
// MODIFIERS
// ========================
// .EmbedBlock--modifier {}

// ========================
// UI STATES
// ========================
// .EmbedBlock.is-active {}
