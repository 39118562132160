// ========================
// QUOTE
// ========================
// @note: Used in quote slider
// ========================
.Quote {
  display: block;
  text-decoration: none;
  background-color: $primary-dark;
  border-radius: 2px;
  color: $white;
  text-align: center;
  box-shadow: 0 1px 5px rgba($black, 0.3);
  user-select: none;
  transition: background-color 150ms linear;

  figure {
    position: relative;
    margin-bottom: $v;
  }
}

// ========================
// ELEMENTS
// ========================
.Quote-content {
  margin: 0;
  padding: $v * 0.5 $h * 0.5;
}

.Quote-citation {
  padding: 0 $h * 0.5 $v * 1.5;

  cite {
    @extend %caption;
    font-style: normal;
  }
}

.Quote-icon {
  position: absolute;
  bottom: 0;
  left: 50%;
  background-color: $green-dark;
  box-shadow: 0 1px 5px rgba($black, 0.3);
  width: $h * 2;
  height: $v * 2;
  transform: translate(-50%, 50%);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  .icon {
    @include font(24px);
  }

  @media #{$tablet} {
    background-color: $green;
    transform: translate(-50%, 50%) scale(0.9);
    transition: all 150ms ease(in-out-quart);
  }
}

// ========================
// MODIFIERS
// ========================
// .Quote--modifier {}

// ========================
// UI STATES
// ========================
.Quote:hover {
  color: $white;
  background-color: $blue;

  @media #{$tablet} {
    .Quote-icon {
      background-color: $green-dark;
      transform: translate(-50%, 50%) scale(1);
    }
  }
}
