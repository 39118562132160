// ========================
// TILE LINKS BLOCK
// ========================
// @note: Wraps tiles in a grid and is used for displaying them on various layouts
// ========================
.TileLinksBlock {
  @extend %block-spacing !optional;
  text-align: left;
}

// ========================
// ELEMENTS
// ========================
// .TileLinksBlock-part {}
// .TileLinksBlock-anotherPart {}

// ========================
// MODIFIERS
// ========================
// .TileLinksBlock--modifier {}

// ========================
// UI STATES
// ========================
// .TileLinksBlock.is-active {}
