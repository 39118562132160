@use "sass:math";

// ========================
// Buttons
// ========================

// Base button styles
.Button {
  @include font(19px);
  @extend %font-bold !optional;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  appearance: none;
  text-decoration: none;
  cursor: pointer;
  height: $button-height-sm;
  margin: 0;
  padding: 0 $button-horizontal-padding;
  position: relative;
  text-align: center;
  user-select: none;
  white-space: normal;
  border: none;
  border-radius: $button-border-radius;
  min-width: $button-min-width;
  transition: all $button-transition-speed ease-in-out;

  @media #{$laptop} {
    @include font(21px);
    height: $button-height-lg;
  }
}

// ========================
// MODIFIERS
// ========================

// Full-width
.Button--block {
  width: 100%;
}

// Small
.Button--small {
  @include font(15px);
  min-width: 0;

  @media #{$laptop} {
    padding: 20px 40px;
    height: $button-height-lg * 0.8;
  }
}

.Button--medium {
  @include font(17px);
  padding: $button-vertical-padding * 0.8 $button-horizontal-padding * 1.5;
}

// Large
.Button--large {
  @include font(18px);
  height: $button-height-sm * 1.2;
  padding: 0 $button-horizontal-padding * 1.2;
  min-width: $button-min-width * 1.2;

  @media #{$laptop} {
    @include font(20px);
    height: $button-height-lg * 1.2;
  }
}

.Button--rounded-heavy {
  border-radius: 30px;
}

// ========================
// UI STATES
// ========================
.Button {
  &:hover,
  &:focus,
  &:active,
  &:disabled,
  &.is-disabled,
  &.is-loading {
    text-decoration: none;
    outline-offset: 2px;
  }

  &.is-loading {
    cursor: wait;
  }

  &:disabled,
  &.is-disabled {
    cursor: not-allowed;
    opacity: $button-disabled-opacity;
  }
}
