// ============
// $TEXT
// ============


// ============
// Horizontal text alignment
// ============
@mixin text-align($namespace: "") {
  .u-#{$namespace}textCenter { text-align: center !important; }
  .u-#{$namespace}textLeft { text-align: left !important; }
  .u-#{$namespace}textRight { text-align: right !important; }
  .u-#{$namespace}textJustify { text-align: justify !important; }
}
@include text-align();
@media #{$mobile-only} { @include text-align("sm-"); }
@media #{$tablet} { @include text-align("md-"); }
// @media #{$laptop} { @include text-align("lg-"); }
// @media #{$desktop} { @include text-align("xl-"); }
// @media #{$widescreen} { @include text-align("xx-"); }


// ============
// Word breaking
// ============
// Break strings when their length exceeds the width of their container
.u-textBreakWord { word-break: break-word !important; }
.u-textBreakAll { word-break: break-all !important; }


// ============
// Prevent whitespace wrapping
// ============
// Avoid unsightly breaks e.g. <span class="u-textNoWrap">New Zealand</span>
.u-textNoWrap { white-space: nowrap !important; }


// ============
// Text truncation ...
// ============
// Prevent text from wrapping onto multiple lines, and truncate with an ellipsis.
// 1. Ensure that the node has a maximum width after which truncation can occur.
// 2. Fix for IE 8/9 if `word-wrap: break-word` is in effect on ancestor nodes.
.u-textTruncate {
  max-width: 100%; // 1
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  word-wrap: normal !important; // 2
}


// ============
// Text selection
// ============
.u-textNoSelect {
  user-select: none;
}

// ============
// Font colours
// ============
.u-textDark { color: $text; }
.u-textLight { color: $text-light; }
.u-textGreen { color: $green-dark; }
.u-textBlue { color: $blue; }

// Inherit the ancestor's text color. ============
// .u-textInheritColor { color: inherit !important; }

