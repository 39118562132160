@use "sass:math";

// ========================
// HEADER
// ========================
// @note: Used globally, fixed to the top of the site
// ========================
.Header {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  position: fixed;
  background-color: $white;
  width: 100%;
  left: 0;
  top: 0;
  right: 0;
  z-index: $z-header;
  height: $header--height-sm;
  box-shadow: 0 1px 5px rgba($black, 0.2);

  @media #{$laptop} {
    height: $header--height-lg;
  }

  .Container {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    // margin-bottom: $header--spacing-y;
    @media #{$mobile-only} {
      width: 100%;
    }
  }

  &.is-preview {
    height: $header--height-sm + 24px;
    @media #{$laptop} {
      height: $header--height-lg + 24px;
    }
  }
}

// ========================
// ELEMENTS
// ========================
.Header-logo {
  display: block;
  width: 220px;
  height: 50px;

  @media #{$laptop} {
    width: 300px;
    height: 68px;
  }

  &::after {
    content: "";
    display: block;
    position: static;
    width: 100%;
    padding-top: math.div(68, 300) * 100%; // logo aspect ratio
    background: 0 0 / contain url("/images/logos/TPG-2023-logo.svg") no-repeat;
  }
}

// .Header-anotherPart {}

// ========================
// MODIFIERS
// ========================
// .Header--modifier {}

// ========================
// UI STATES
// ========================
// .Header.is-active {}
