// ========================
// QuotesSlider
// ========================
// @note: Holds the testimonial quotes
// ========================
.QuotesSlider {
  background-color: $grey-light;
  padding-top: rem(30px);
  padding-bottom: rem(40px);
  position: relative;

  // https://www.bugherd.com/projects/259469/tasks/55
  // @media #{$tablet} {
  //   background: $grey-light center/cover url('/images/ui/background-logomark-dark-positionedleft.png') no-repeat;
  // }

  @media #{$laptop} {
    padding-top: rem(40px);
    padding-bottom: rem(60px);
  }
}

// ========================
// ELEMENTS
// ========================
.QuotesSlider-title {
  text-align: center;
  margin-bottom: $v;
  margin-top: 0;
}

.QuotesSlider-slider {
  position: relative;

  .swiper-wrapper {
    align-items: center;
  }

  .swiper-slide {
    padding-bottom: $v * 0.5;
  }

  @media #{$tablet} {
    padding: 0 $h * 0.5;
  }
}

.QuotesSlider-slider-btn {
  display: none;
  appearance: none;
  border: none;
  padding: 0;

  @media #{$tablet} {
    @include font(18px);
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $blue-dark;
    color: $white;
    width: $h;
    height: $v;
    border-radius: 50%;
    top: 50%;
    transform: translateY(-50%);
    transition: background-color 150ms linear;

    &:hover {
      background-color: $green;
    }
  }
}

// ========================
// MODIFIERS
// ========================
.QuotesSlider-slider-btn--prev {
  right: 100%;
}

.QuotesSlider-slider-btn--next {
  left: 100%;
}

// ========================
// UI STATES
// ========================
// .QuotesSlider.is-active {}
