// ========================
// FOOTER
// ========================
// @note: Global component used at the bottom of layouts
// ========================
.Footer {
  background-color: $primary-dark;
  padding-top: 40px;
  padding-bottom: 20px;
  color: $white;

  .column-title {
    @extend %font-bold !optional;
    font-size: 1.5rem;
  }

  .title {
    display: flex;
    align-items: center;
    margin-top: 3.5rem;
    margin-bottom: 3.5rem;
    cursor: pointer;
    width: max-content;

    .large-arrow {
      width: 2.5rem;
      height: 2.5rem;
      transition: all 0.2s ease-in-out;
      margin-top: 10px;
    }

    &:hover {
      .large-arrow {
        transition: all 0.2s ease-in-out;
        transform: scale(1.2);
      }
    }

    .title-text {
      @extend %font-bold !optional;
      font-size: 3rem;
      margin-right: 20px;
      margin-bottom: 0;
      color: $teal;
    }
  }

  a {
    color: $white;
    @include hover() {
      color: $teal;
    }
  }

  @media #{$tablet} {
    padding-top: 40px;
    padding-bottom: 40px;

    .title {
      .title-text {
        font-size: 4.5rem;
      }

      .large-arrow {
        width: 3rem;
        height: 3rem;
        margin-top: 20px;
      }
    }
  }
}

// ========================
// ELEMENTS
// ========================
.Footer-contact {
  @extend %small !optional;
  padding: 0;
  margin: 0;
  list-style: none;

  > li {
    line-height: 1;
    margin-bottom: 10px;
    position: relative;
  }
}

.Footer-contact-link {
  text-decoration: none;
}

.Footer-legal {
  margin-top: 30px;
  padding-top: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  color: $grey;

  .legal-desktop {
    display: none;
  }

  p {
    @extend %small-semibold !optional;
  }

  @media #{$tablet} {
    margin-top: 0;
    padding-top: 30px;

    .legal-mobile {
      display: none;
    }

    .legal-desktop {
      display: flex;
      width: 100%;
      justify-content: space-between;
      flex-direction: row;
    }
  }
}

.Footer-legal-links {
  text-align: center;

  a {
    @extend %small-semibold !optional;
    margin-bottom: 5px;
    color: $grey;
    text-decoration: none;
    display: inline-block;
  }

  @media #{$tablet} {
    text-align: left;
    margin-bottom: 0;

    a {
      &:not(:last-child) {
        margin-right: 30px;
      }
    }
  }
}

.Footer-strapline {

  // Make sure the text wraps nicely onto 2 lines
  @media #{$tablet} {
    max-width: 40%;
  }
  @media #{$laptop} {
    max-width: unset;
    padding-right: 80px !important;
  }
}

// .Footer-form {}

// .Footer-form-field {}


// ========================
// MODIFIERS
// ========================
// .Footer--modifier {}

// ========================
// UI STATES
// ========================
// .Footer.is-active {}
