// ========================
// PREVIEW BANNER
// ========================
// @note: Shown when in CMS preview
// ========================
.Preview {
  position: absolute;
  top: 100%;
  width: 100%;
  background: $warning;
  color: $white;
  padding: 10px $h * 0.5;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media #{$tablet} {
    justify-content: center;
  }
}

// ========================
// ELEMENTS
// ========================
.Preview-text {
  margin: 0 $h * 0.5 0 0;

  @media #{$tablet} {
    text-align: center;
  }
}

// ========================
// MODIFIERS
// ========================
// .Preview--modifier {}

// ========================
// UI STATES
// ========================
// .Preview.is-active {}
