// ========================
// AUTHOR
// ========================
// @note: Used at the bottom of news articles
// ========================
.Author {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-top: 1px solid $text;
  padding-top: $v;
  margin-top: $v;

  @media #{$tablet} {
    flex-direction: row;
    align-items: center;
  }
}

// ========================
// ELEMENTS
// ========================
.Author-image {
  overflow: hidden;
  border-radius: $image-border-radius;
  flex-shrink: 0;
  width: 100%;
  height: 250px;

  img {
    object-fit: cover;

    // Make it so that the face is always visible
    object-position: 0 0;
  }

  @media #{$tablet} {
    width: 400px;
  }
}

.Author-details {
  margin-left: $h * 0.5;

  .name {
    @extend %h3 !optional;
    color: $teal;
  }

  .job-title {
    @extend %font-bold !optional;
  }

  @media #{$tablet} {
    .name {
      margin-top: 0;
    }
    margin-left: $h;
  }
}

.Author-details-list {
  padding: 0;
  margin: 10px 0 0 -2px;
  list-style: none;

  &--img img {
    object-fit: contain;
    width: fit-content !important;
    display: block;
    height: 48px;
  }

  > li {
    @extend %small !optional;
    margin: 0;
    line-height: 1;
    position: relative;
    padding-left: 24px;

    &:not(:last-child) {
      margin-bottom: 5px;
    }
  }

  .icon {
    position: absolute;
    top: -1px;
    left: 0;
    color: $grey-dark;
  }
}
// ========================
// MODIFIERS
// ========================
// .Author--modifier {}

// ========================
// UI STATES
// ========================
// .Author.is-active {}
