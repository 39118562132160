// ============
// $CONTAINER
// ============

%Container {
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding-left: $container-gutter;
  padding-right: $container-gutter;
  width: 100%;

  @media #{$laptop} {
    padding-left: $container-gutter-lg;
    padding-right: $container-gutter-lg;
  }

  @if $fixed-breakpoints == true {
    @media #{$tablet} { width: rem($container1); }
    @media #{$laptop} { width: rem($container2); }
    @media #{$desktop} { width: rem($container3); }
    // @media #{$widescreen} { width: rem($container4); }
  }
}

.Container { @extend %Container !optional; }
