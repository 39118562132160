// ============
// Z-indices
// ============
$z-0: 0;
$z-10: 10;
$z-20: 20;
$z-30: 30;
$z-40: 40;
$z-50: 50;

// namespaced
$z-header: $z-10;


// ============
// Global elements
// ============
$header--height-sm: 84px;
$header--height-lg: 100px;
$header--spacing-y: 12px;

// ============
// Form elements
// ============
$form-element-height:          40px; // button, select
$form-element-border-colour:   rgba($black, 0.25);
$form-element-border-width:    2px;
$form-element-border-radius:   5px;
$form-element-focus-color:     $primary;
$form-element-disabled-color:  transparentize($grey-med, 0.5);
$form-element-padding:         $h * 0.5;
$form-element-heavy-border-radius: 20px;

// ============
// Images
// ============
$image-border-radius: 5px;

// ============
// Other
// ============
$min-page-intro-height-sm: 200px;
$min-page-intro-height-lg: 200px;
