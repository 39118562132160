// ========================
// ORGANISATION SLIDER
// ========================
// @note: Holds client logos in a belt
// ========================
.OrgSlider {
  padding-top: rem(30px);
  padding-bottom: rem(40px);
  position: relative;

  @media #{$laptop} {
    padding-top: rem(40px);
    padding-bottom: rem(60px);
  }
}

// ========================
// ELEMENTS
// ========================
.OrgSlider-title {
  text-align: center;
  margin-bottom: $v;
  margin-top: 0;
}

.OrgSlider-slider {
  position: relative;

  .swiper-wrapper {
    align-items: center;
  }

  @media #{$tablet} {
    padding: 0 $h * 0.5;
  }
}

.OrgSlider-slider-btn {
  display: none;
  appearance: none;
  border: none;
  padding: 0;

  @media #{$tablet} {
    @include font(18px);
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $blue-dark;
    color: $white;
    width: $h;
    height: $v;
    border-radius: 50%;
    top: 50%;
    transform: translateY(-50%);
    transition: background-color 150ms linear;

    &:hover {
      background-color: $green;
    }
  }
}

// ========================
// MODIFIERS
// ========================
.OrgSlider-slider-btn--prev {
  right: 100%;
}

.OrgSlider-slider-btn--next {
  left: 100%;
}

// ========================
// UI STATES
// ========================
// .OrgSlider.is-active {}
