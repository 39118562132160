// ============
// $ICONS
// ============

// Usage
// CSS:
// .my-element:before {
//   @extend %icon !optional;
//   @extend .icon-close !optional;
//   ...
// }
// HTML:
// <i class="icon icon-close"></i>

@font-face {
  font-family: 'TPG Glyphs';
  src: url('/fonts/glyphs/TPG-Glyphs.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

// NOTE: Extend a %placeholder selector here, to avoid having .icon @extended every time we add styles to a .icon within a component
%icon {
  /* stylelint-disable-next-line font-family-no-missing-generic-family-keyword */
  font-family: 'TPG Glyphs' !important; // use !important to prevent issues with browser extensions that change fonts
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
}
.icon { @extend %icon !optional; }

/* stylelint-disable rule-empty-line-before, selector-pseudo-element-colon-notation */
// === PASTE ICON CONTENT BELOW ===
.icon-home:before {
  content: "\e900";
}
.icon-office:before {
  content: "\e903";
}
.icon-phone:before {
  content: "\e942";
}
.icon-mail:before {
  content: "\e945";
}
.icon-location:before {
  content: "\e947";
}
.icon-user:before {
  content: "\e971";
}
.icon-users:before {
  content: "\e972";
}
.icon-linkedin:before {
  content: "\eaca";
}
.icon-arrow-up:before {
  content: "\ea3a";
}
.icon-arrow-right:before {
  content: "\ea3c";
}
.icon-arrow-down:before {
  content: "\ea3e";
}
.icon-arrow-left:before {
  content: "\ea40";
}
.icon-circle-up:before {
  content: "\ea41";
}
.icon-circle-right:before {
  content: "\ea42";
}
.icon-circle-down:before {
  content: "\ea43";
}
.icon-circle-left:before {
  content: "\ea44";
}
.icon-plus:before {
  content: "\ea0a";
}
.icon-minus:before {
  content: "\ea0b";
}
.icon-checkmark:before {
  content: "\e901";
}
.icon-close:before {
  content: "\e902";
}
.icon-search:before {
  content: "\e904";
}
.icon-link:before {
  content: "\e9cb";
}
.icon-attachment:before {
  content: "\e9cd";
}
.icon-notification:before {
  content: "\ea08";
}
.icon-info:before {
  content: "\ea0c";
}
.icon-quote:before {
  content: "\e977";
}
.icon-check:before {
  content: "\ea10";
}
/* stylelint-enable rule-empty-line-before, selector-pseudo-element-colon-notation */
