// ========================
// PAGE TILE
// ========================
// @note: Tiles used to link between pages and stuff
// ========================
.PageTile {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  text-decoration: none;
}

// ========================
// ELEMENTS
// ========================
.PageTile-image {
  border-radius: $image-border-radius $image-border-radius 0 0;
  background-clip: padding-box;
  overflow: hidden;
  opacity: 1;
  flex-shrink: 0;
  transition: opacity 150ms linear;
}

.PageTile-caption {
  padding: 24px;
  border-radius: 0 0 $image-border-radius $image-border-radius;
  background-color: $blue-dark;
  background-clip: padding-box;
  color: $white;
  flex-grow: 1;
  transition: background 150ms linear;
  margin-top: -1px;

  a {
    color: white;
  }
}

.PageTile-caption-title {
  @extend %font-bold !optional;
  font-size: 1rem;
  color: $white;
  margin: 0;
}

.PageTile-caption-date {
  @extend %font-bold !optional;
  color: $white;
  margin-top: rem(5px);
  margin-bottom: rem(15px);
}

.PageTile-caption-intro {
  @extend %font-bold !optional;
  color: $white;
  margin-bottom: 0;
}

.PageTile-caption-intro p {
  font-size: 0.8rem;
  margin-bottom: 0;
}
// ========================
// MODIFIERS
// ========================
// .PageTile--modifier {}

.PageTile--portrait {
  .PageTile-caption {
    .PageTile-caption-title {
      @extend %font-semibold !optional;
      color: $teal;
      font-size: 1.4rem;
    }

    .PageTile-caption-date {
      @extend %font-semibold !optional;
      font-size: 0.9rem;
    }

    .PageTile-caption-intro {
      @extend %font-semibold !optional;
      font-size: 0.9rem;

      p {
        text-align: unset !important;
      }
    }
  }
}

// ========================
// UI STATES
// ========================
.PageTile:hover {
  .PageTile-caption {
    background: $teal;

    .PageTile-caption-title, .PageTile-caption-date, .PageTile-caption-intro {
      color: $blue-dark;

      a {
        color: $blue-dark;
      }
    }
  }
}

.PageTile--portrait:hover {
  .PageTile-caption {
    .PageTile-caption-title {
      color: $blue-dark;
    }
  }
}
