// ========================
// VIDEO
// ========================
// @note: Core responsive video player styles
// ========================

%video {
  @include fit-media(16, 9); // aspect ratio of 16:9
}

.Video { @extend %video !optional; }

// ========================
// MODIFIERS
// ========================
// .Video--youtube {}
// .Video--vimeo {}

// ========================
// UI STATES
// ========================
// .Video.is-active {}
