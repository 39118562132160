// ========================
// STRUCTURED TEXT OVERRIDES
// ========================
// @note: Used to re-style CMS edited content
// ========================
%StructuredText {
  h3, .h3,
  h4, .h4,
  h5, .h5 {
    @extend %font-bold;
  }

  h6, .h6 {
    @extend %lead;
  }

  h1, .h1,
  h2, .h2 {
    @extend %font-heading;
  }

  h3, .h3 {
    color: $teal;
  }

  *:last-child {
    margin-bottom: 0;
  }

  p, .p {
    & + ul,
    & + ol {
      margin-top: rem($base-paragraph-spacing);

      li {
        margin-bottom: rem($base-paragraph-spacing - 20px);
      }

      &:last-child {
        margin-bottom: 0;
      }

      & ul,
      & ol {
        margin-top: rem($base-paragraph-spacing);
      }
    }
  }
}

.u-structuredText {
  @extend %StructuredText;
}
