// ========================
// IMAGE BLOCK
// ========================
// @note:
// ========================
.ImageBlock {
  @extend %block-spacing !optional;
}

// ========================
// ELEMENTS
// ========================

.ImageBlock-caption {
  @extend %caption !optional;
  margin-top: rem(12px);
  color: $grey-dark;
}

.ImageBlock-description {
  margin: rem(30px) 0;
  width: 100%;
}

.ImageBlock-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: rem(20px) 0;
}

.ImageBlock-image {
  border-radius: $image-border-radius;
}

// ========================
// MODIFIERS
// ========================
// .ImageBlock--modifier {}

// ========================
// UI STATES
// ========================
// .ImageBlock.is-active {}
