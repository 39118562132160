// ========================
// FEATURED INTRO BLOCK
// ========================
// @note: Large introduction block with stylised background
// ========================
.FeaturedIntro {
  color: $blue-dark;
  text-align: center;
  background: $teal;
  padding: 30px 0;

  // https://www.bugherd.com/projects/259469/tasks/55
  // @media #{$tablet} {
  //   background: $green-dark center/cover url('/images/ui/background-logomark-dark-positionedright.png') no-repeat;
  // }

  @media #{$laptop} {
    padding: 80px 0;
  }

  // title content
  span {
    @extend %font-bold !optional;
    font-size: 1.1rem;

    @media #{$tablet} {
      font-size: 1.4rem;
    }

    @media #{$laptop} {
      font-size: 1.7rem;
    }

    @media #{$desktop} {
      font-size: 2rem;
    }
  }
}
