// ========================
// PAGE FORM SECTION
// ========================
// @note: Holds the contact form
// ========================
.PageForm {
  padding: 30px 0;
  color: $blue-dark;
  background: $teal;

  @media #{$laptop} {
    padding: 60px 0;
  }
}

// ========================
// ELEMENTS
// ========================
// .PageForm-form {}

.PageForm-form-field {
  position: relative;
  appearance: none;
  border: 0;
  margin: 0 0 $v * 0.5;
}

// ========================
// MODIFIERS
// ========================
// .PageForm--modifier {}

// ========================
// UI STATES
// ========================
// .PageForm.is-active {}
