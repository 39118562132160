// ========================
// PAGE HERO BANNER
// ========================
// @note: Large hero image used at the top of pages
// ========================

.PageHero {
  position: relative;
  width: 100%;

  height: calc(100vh - #{$header--height-sm} - #{$min-page-intro-height-sm});

  @media #{$laptop} {
    height: calc(100vh - #{$header--height-lg} - #{$min-page-intro-height-lg});
  }
}

// ========================
// ELEMENTS
// ========================
.PageHero-title {
  max-width: 340px;
  z-index: 2;
  position: absolute;
  height: 100%;

  // Vertically center content
  display: flex;
  align-items: center;

  h1 {
    @extend %font-bold !optional;
    font-size: rem(27px);

    color: $white;
    margin: 0;
    padding: 0;

    @media #{$tablet} {
      font-size: rem(48px);
    }

    @media #{$desktop} {
      font-size: rem(56px);
    }
  }

  // Set these width values to make sure the slogan fits nicely (should be 2 lines of text)
  @media #{$tablet} {
    max-width: 580px;
  }

  @media #{$laptop} {
    max-width: 695px;
  }
}

.PageHero-image {
  height: 100%;

  img {
    object-fit: cover;
    object-position: 50% 50%;
  }
}

// ========================
// MODIFIERS
// ========================
// .PageHero--modifier {}
.PageHero--home-page {
  height: calc(100vh - #{$header--height-sm});

  @media #{$laptop} {
    height: calc(100vh - #{$header--height-lg});
  }
}

// ========================
// UI STATES
// ========================
// .PageHero.is-active {}
