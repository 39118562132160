@use "sass:math";

// ========================
// MULTI SELECT
// ========================
// @note: Using "react-multi-select-component":
// https://github.com/hc-oss/react-multi-select-component
// MIT © harshzalavadiya
// ========================

$checkbox-size: 20px;

.MultiSelect {
  // Override react lib theme:
  &.rmsc {
    // focus ring
    --rmsc-main: #{$teal};
    // hover
    --rmsc-hover: #{$teal-hover};
    // active
    --rmsc-selected: #{$white};
    // border
    --rmsc-border: #{$border};
    // text and icons
    --rmsc-gray: #{$grey};
    // background
    --rmsc-bg: #{$white};
    // spacing
    --rmsc-p: #{$form-element-padding};
    // radius
    --rmsc-radius: 20px;
    // height
    --rmsc-h: #{$form-element-height};

    @extend %font-regular !optional;
    @extend %small !optional;
    @include font(16px, 24px);
    @media #{$laptop} {
      @include font(18px, 27px);
    }
    margin: 0;
  }
}

// ========================
// ELEMENTS
// ========================
.MultiSelect.rmsc .dropdown-container {
  background: $grey-light;
  border: none;

  .dropdown-heading {

    // placeholder 'Search...' or the label of the selected value/s
    .dropdown-heading-value span {
      @include font(16px);
      @extend %font-semibold !optional;
      color: $grey-dark;
    }
  }

  &:focus, &:active, &:hover {
    background: $teal;

    .dropdown-heading {
      .dropdown-heading-value span {
        color: $blue-dark;
      }

      // chevron and clear/'x' icons
      svg {
        color: $blue-dark;
      }
    }
  }
}

.MultiSelect.rmsc .dropdown-content {
  .panel-content {
    border-radius: $form-element-border-radius;
    padding: 10px 0;
  }
}

.MultiSelect.rmsc .select-item {
  margin: 0;
  padding: 0 var(--rmsc-p);
  height: var(--rmsc-h);
  display: flex;
  align-items: center;

  // Custom checkbox
  &::before {
    content: ' ';
    display: block;
    text-align: center;
    width: $checkbox-size;
    height: $checkbox-size;
    box-shadow: 0 0 0 1px $teal;
    margin-right: math.div($h, 2);
    border-radius: 2px;
  }

  // Apply checked styling
  &.selected {
    &::before {
      box-shadow: 0 0 0 1px $teal;
      background-color: $teal;
      background-image: url('/images/icons/icon-tick.svg') !important;
      background-size: 15px !important;
      background-repeat: no-repeat !important;
      background-position: center !important;
    }
  }
}

.MultiSelect.rmsc .select-item:focus {
  background: transparent;
}

.MultiSelect.rmsc .select-item:hover {
  background: $teal-hover;
}

.MultiSelect.rmsc .item-renderer {
  align-items: center;

  // label
  span {
    display: block;
    line-height: 1.5;
    @include font(16px);
    @extend %font-semibold !optional;
  }

  // checkbox
  input {
    // Hide the real input so we can apply our own checkbox styling (see .MultiSelect.rmsc .select-item)
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }
}

// ========================
// MODIFIERS
// ========================
// .MultiSelect--modifier {}

// ========================
// UI STATES
// ========================
// .MultiSelect.is-active {}
