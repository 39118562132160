// ========================
// IMAGE TEXT BLOCK
// ========================
// @note:
// ========================
.ImageTextBlock {
  @extend %block-spacing !optional;
}

// ========================
// ELEMENTS
// ========================
.ImageTextBlock-image {
  overflow: hidden;
  margin-top: $v;
}

.ImageTextBlock--no-title {
  .ImageTextBlock-image {
    margin-top: 0;
  }
}

// ========================
// MODIFIERS
// ========================
// .ImageTextBlock--modifier {}

// ========================
// UI STATES
// ========================
// .ImageTextBlock.is-active {}
