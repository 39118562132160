@use "sass:math";

// ============
// $TEXTAREA
// ============

.Textarea {
  position: relative;
  display: block;
  padding-top: 30px;

  textarea {
    @extend %font-regular !optional;
    @include font(16px, 24px);
    @media #{$laptop} { @include font(18px, 27px); }

    display: block;
    line-height: $base-line-height;
    width: 100%;
    resize: vertical;
    box-shadow: inset 0 0 5px 0 rgba(173, 173, 173, 0.5);
    border: solid 1px $border;
    padding: math.div($v, 2) math.div($h, 2);
    min-height: 100px;
    outline: none;
    border-radius: $form-element-border-radius;


    // Error Styles ============
    &.has-error {
      box-shadow: inset 0 0 5px 0 rgba($error, 0.5);
      border-color: $error;
      color: $error;
    }

    &:focus {
      box-shadow: 0 0 0 1px $form-element-focus-color;
      border-color: $form-element-focus-color;
    }
  }
}

.Textarea--teal {
  textarea {
    @include placeholder($blue-dark);
    color: $blue-dark;
    background: $teal-input;
    border: none;
  }
}

.Textarea--rounded-heavy {
  textarea {
    border-radius: $form-element-heavy-border-radius;
  }
}


// ============
// Character limit counter
// ============
.Textarea-charCount {
  @extend .small !optional;
  line-height: $v;
  position: absolute;
  bottom: 0;
  right: 0;
  color: $grey-med;
  user-select: none;
}
