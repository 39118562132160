// ========================
// PAGE INTRO
// ========================
// @note: Used on most general pages at the top
// - Title (required)
// - Intro (optional)
// - Service icons (optional)
// ========================
.PageIntro {
  padding: 30px 0;
  background-color: $teal;

  min-height: $min-page-intro-height-sm;

  display: flex;
  justify-content: center;
  align-items: center;

  // https://www.bugherd.com/projects/259469/tasks/55
  // @media #{$tablet} {
  //   background: $grey-light center/cover url('/images/ui/background-logomark-dark-positionedleft.png') no-repeat;
  // }

  h1 {
    @extend %font-bold !optional;
  }

  @media #{$laptop} {
    min-height: $min-page-intro-height-lg;
    padding: 60px 0;
  }
}

// ========================
// ELEMENTS
// ========================
.PageIntro-content {
  flex-grow: 1;
  width: 100%;

  .introduction {
    margin-top: 1rem;

    span, p {
      @extend %semibold !optional;
    }

    a {
      color: $text;

      &:hover {
        color: $white;
      }
    }
  }

  @media #{$tablet} {
    width: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .introduction {
      max-width: 35%;
      margin-top: 0;

      span, p {
        @include font(21px);
      }
    }
  }
}

.PageIntro-services {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
  margin-top: $v * 0.5;
  text-align: center;

  a { text-decoration: none; }

  @media #{$tablet} {
    justify-content: flex-end;
    flex-wrap: nowrap;
    margin-top: 0;
    margin-left: $h;
    width: auto;

    li {
      width: 80px;
      &:not(:last-child) { margin-right: $h * 0.5; }
    }
  }

  @media #{$laptop} {
    li { width: 100px; }
  }
}

.PageIntro-services-icon {
  height: 60px;
  width: 60px;
  flex-shrink: 0;
  border-radius: 50%;

  @media #{$tablet} {
    height: 80px;
    width: 80px;
    transition: all 150ms linear;
  }

  @media #{$laptop} {
    height: 100px;
    width: 100px;
  }
}

.PageIntro-services-label {
  @extend %caption !optional;
  @include font(12px);
  padding: 4px 6px;
  background: $green-dark;
  color: $white;
  border-radius: 2px;
  margin: 0 4px 4px 0;
  transition: all 150ms linear;

  @media #{$tablet} {
    padding: 0 5px;
    margin: 10px 0 0 0;
    background: none;
    border-radius: 0;
    color: $text;
  }
}

// ========================
// Modifiers
// ========================
.PageIntro--horizontal {
  .PageIntro-content {
    display: block;

    .introduction {
      margin-top: 20px;
      max-width: 90%;
    }
  }
}

.PageIntro--case-study {
  .PageIntro-content {
    .introduction {
      span, p {
        @extend %font-regular !optional;
      }
    }
  }
}

.PageIntro--person {
  .PageIntro-content {
    .introduction {
      @extend %font-semibold !optional;
    }
  }
}

// ========================
// UI STATES
// ========================
.PageIntro-services a {
  @include hover() {
    .PageIntro-services-label {
      background: $blue-light;
    }

    @media #{$tablet} {
      .PageIntro-services-icon {
        opacity: 0.75;
      }

      .PageIntro-services-label {
        background: none;
        color: $green-dark;
      }
    }
  }
}
