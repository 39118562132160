// ========================
// OFFICE
// ========================
// @note: Single office item used in various locations and layouts
// ========================
.Office {
  font-style: normal;

  h3 {
    color: $teal-dark;
    @include font(25px);
  }

  color: $blue-dark;

  a {
    text-decoration: none;
    color: $blue-dark;

    &:focus, &:hover {
      color: $teal;
    }
  }
}

// ========================
// ELEMENTS
// ========================
.Office-details {
  list-style: none;
  padding: 0;
  margin: 0;
}

.Office-details-item {
  @extend %small !optional;
  margin-bottom: rem(5px);
  display: flex;
  color: $blue;

  strong {
    color: $teal-dark;
    margin-right: 3px;
  }

  .icon {
    line-height: inherit;
    margin-right: $h * 0.3;
    color: $grey-dark;
  }
}

// ========================
// MODIFIERS
// ========================
// .Office--modifier {}

// ========================
// UI STATES
// ========================
// .Office.is-active {}
