$transition: all 0.2s ease;

/* styles for job board imbed */
.BambooHR-ATS-board {
  text-align: left;

  h2 {
    @extend %h3 !optional;
    @extend .u-textBold;
  }
}

// <ul>
.BambooHR-ATS-Department-List {
  list-style: none;
  padding: 0;
  margin-top: 0;
  margin-bottom: $v * 0.25;
}

// <li>
.BambooHR-ATS-Department-Item {
  margin-bottom: 50px;
}

// <div>
.BambooHR-ATS-Department-Header {
  @extend %font-bold !optional;
  font-size: 20px;
  margin-bottom: 10px !important;
  color: $teal;
  text-transform: lowercase;

  &::first-letter {
    text-transform: uppercase;
  }
}

// <ul>
.BambooHR-ATS-Jobs-List {
  list-style: none;
  margin: 0;
  padding: 0;
  border-top: 1px solid $blue-dark;
}

.BambooHR-Footer {
  @extend %caption;
  text-align: center;
  color: $blue-dark;
  margin: 0;
  padding-top: 20px !important;

  img { display: inline; }
}


// <li>
.BambooHR-ATS-Jobs-Item {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  border-bottom: 1px solid $blue-dark;
  cursor: pointer;
  transition: $transition;

  a {
    @extend %font-regular !optional;
    margin: 0 !important;
    display: block;
    position: relative;
    padding-top: $v * 0.3;
    transition: $transition;
    text-decoration: none;
    color: $blue-dark;
    flex-grow: 1; // Make the entire bar (up to the location <span>) clickable/linkable
  }

  @media #{$tablet} {
    a {
      padding: $v * 0.5 0;
    }
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  @media #{$laptop} {
    @include hover() {
      background: $teal-hover;

      a {
        padding-left: 10px;
        color: $blue-dark;
      }

      // .BambooHR-ATS-LOcation (<span>) arrow icon with circle background
      span::after {
        background: $teal;
        color: $blue-dark;
      }
    }
  }
}

// <span>
.BambooHR-ATS-Location {
  @extend %font-regular !optional;
  display: block;
  color: $blue-dark;
  position: relative;
  padding-bottom: $v * 0.3;
  padding-right: $h + 0.8;
  transition: $transition;

  // Arrow icon with circle background
  &::after {
    transition: $transition;
    border-radius: 50%;

    // Align with text content
    position: absolute;
    right: 0;
    top: -17%;

    width: $h - 0.2;
    height: $h - 0.2;
    text-align: center;
    color: $blue-dark;
    line-height: 1.4;
    background: $grey-light;
    display: inline-block;

    // Arrow icon
    background-image: url('/images/icons/icon-arrow-small.svg') !important;
    background-size: 12px !important;
    background-repeat: no-repeat !important;
    background-position: center !important;

    @media #{$tablet} {
      top: -25%;
    }

    content: "";
  }

  @media #{$tablet} {
    padding-top: $v * 0.5;
    padding-bottom: $v * 0.5;
    &::after {
      width: $h;
      height: $h;

      // Vertically center
      top: 50%;
      transform: translateY(-50%);

      margin-right: $h * 0.2;
    }
  }
}
